<template>
  <div ref="homeContainer" class="home-container">
    <div class="home-background-container">
      <div class="home-background-image">
        <div class="home-logo">
          <img class="home-all-products" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/All%20Produk%20png_20241023_131329_0002.png" alt="all-product">
        </div>
        <img src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/stiker%20mobil%20aris.png" alt="right-bottom-image" class="right-bottom-image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
}
</script>

<style>
.home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  max-height: 1000px;
  min-height: 600px;
  background-color: #040404;
  min-width: 320px;
  transition: height 1000ms ease;
}
.home-background-container{
  height: 100%;
  width: 100%;
}

.home-background-image{
  background:
      linear-gradient(to top, rgba(0, 0, 0, 1), transparent),
      url('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_1028.jpeg');
  background-size: cover;
  height: 100%;
  width: 100%;
  margin-right: 0;
  margin-left: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(90%);
  z-index: 1;
  display: flex;
  @media (--large) {
    width: 75%;
  }
}
.home-logo {
  background-image: url('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/logo%20dumons%20putih.png');
  background-position: center;
  background-size: 300px;
  width: 320px;
  height: 140px;
  background-repeat: no-repeat;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  @media (--large) {
    margin-left: -200px;
    background-size: 400px;
    width: 400px;
  }
}
.right-bottom-image {
  right: 10px;
  bottom: 10px;
  position: absolute;
  width: 200px;
  height: auto;
}
.home-all-products {
  width: 300px;
  height: auto;
  margin-top: 120px;
  @media (--large) {
    margin-top: 135px;
    width: 400px;
  }
}
</style>
