<template>
  <section class="product-list-container">
    <div class="product-list-inner-container" ref="productListInnerContainer">
      <section class="product-list-top-section">
        <h1 class="product-list-title">PRODUCT LIST</h1>
        <div class="product-list-dropdown-container">
          <div class="product-list" ref="dropDownButton">
            <p class="product-list-placeholder">{{currentProduct}}</p>
            <img class="product-list-placeholder-image" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow.png" alt="arrow-down">
            <div v-if="shouldShowProductLists" class="product-lists" ref="dropDown">
              <p v-for="(dropdown, index) in dropDownsToShow" :key="index" class="product-list-option" @click="changeProduct(dropdown.data.id, index)">{{dropdown.data.name}}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="product-list-bottom-section">
        <div class="product-list-scroll-container" ref="productListScrollContainer">
          <div v-for="(card, index) in productsToShow" :key="index" class="product-card" ref="productCard">
            <div class="product-card-top-section">
              <div class="product-card-grey-background"/>
              <div class="product-card-image-container">
                <img :src="card.data.imageUrl[0]" alt="product-image" class="product-card-image" ref="productImage">
              </div>
            </div>
            <div class="product-card-bottom-section">
              <div class="product-card-descriptions">
                <h1 class="product-card-name">{{card.data.name}}</h1>
                <p class="product-card-description">{{card.data.description2 || card.data.description}}</p>
              </div>
              <button :disabled="disableSeeDetail"
                  class="product-card-button"
                  @click="showModal(
                      this.$refs.productCardButton[index],
                      card.data.imageUrl,
                      card.data.name,
                      card.data.description,
                      card.data.benefits,
                      card.data.detail,
                      card.data.usage,
                      card.data.color,
                      card.data.readyMixUrl,
                      card.data.shopeeUrl
                      )"
                  ref="productCardButton"
              >
                See Detail
              </button>
            </div>
            <img v-if="card.data.badge === true" class="product-list-badge" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/best-seller%20(2).png" alt="bs">
          </div>
        </div>
      </section>
      <section class="product-list-button-container">
        <button :disabled="disableLeftArrow" class="product-list-prev-button" ref="productListPrevButton" @click="showPrevProducts(this.$refs.productListPrevButton)"></button>
        <p style="color: var(--white)">{{this.page}}</p>
        <button :disabled="disableRightArrow" class="product-list-next-button" ref="productListNextButton" @click="showNextProducts(this.$refs.productListNextButton)" ></button>
      </section>
    </div>
  </section>
  <ProductModal
      v-if="shouldShowProductDetail"
      :image-url="selectedData.imageUrl"
      @closeModal="closeModal"
      :name="selectedData.name"
      :benefits="selectedData.benefits"
      :description="selectedData.description"
      :detail="selectedData.detail"
      :usage="selectedData.usage"
      :color="selectedData.color"
      :ready-mix-url="selectedData.readyMixUrl"
      :shopee-url="selectedData.shopeeUrl"
  />
  <LoadingOverlay v-if="isLoading" :should-hide-overlay="shouldHideOverlay" :isVisible='isLoading'/>
</template>

<script>

import LoadingOverlay from "@/components/overlay/loading_overlay/LoadingOverlay.vue";
import ProductModal from "@/components/modal/product_modal/ProductModal.vue";
import {
  catDumons,
  clearCoatReducer,
  ppPrimerSurfacer,
  primerSurfacer, woodCoating,
  zincChromate
} from "@/components/productList/data/products";

function dropDownData(name, id) {
  return {
    data: {
      name: name,
      id: id
    }
  }
}
export default {
  name: 'ProductListPage',
  components: {LoadingOverlay, ProductModal},
  data () {
    return {
      productList: [
      ],
      catDumons: catDumons,
      clearCoatReducer: clearCoatReducer,
      primerSurfacer: primerSurfacer,
      zincChromate: zincChromate,
      ppPrimerSurfacer: ppPrimerSurfacer,
      woodCoating: woodCoating,
      shouldShowProductLists: false,
      shouldShowProductSort: false,
      productsToShow: [],
      showCounter: 0,
      counterMultiplies: 10,
      disableRightArrow: false,
      disableLeftArrow: false,
      intervalId: null,
      index: 0,
      nextArrowDelay: false,
      prevArrowDelay: false,
      page: 1,
      dropDowns: [
        dropDownData("Cat Dumons", 1),
        dropDownData("Clear Coat & Reducer", 2),
        dropDownData("Primer & Surfacer", 3),
        dropDownData("Wood Coating", 4),
        dropDownData("PP Primer 3in1", 5),
        dropDownData("Zinc Chromate", 6)
      ],
      dropDownsToShow: [],
      isLoading: false,
      shouldShowProductDetail: false,
      selectedData:{},
      currentProduct: null,
      disableClickDropdown: false,
      disableSeeDetail: true,
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    document.addEventListener('click', this.handleClickOutside);
    if (urlParams.size > 0) {
      const category = urlParams.get('category')
      const name = urlParams.get('name')?.replace(/_/g, ' ').toLowerCase()
      switch (category) {
        case 'clearCoatReducer': {
          const index = this.clearCoatReducer.findIndex(v => v.data.name.toLowerCase() === name)
          if (index === -1) {
            this.regularRender()
          } else {
            this.openModalByQuery(index, this.clearCoatReducer, 1)
          }
          break
        }
        case 'catDumons': {
          const index = this.catDumons.findIndex(v => v.data.name.toLowerCase() === name)
          if (index === -1) {
            this.regularRender()
          } else {
            this.openModalByQuery(index, this.catDumons, 0)
          }
          break
        }
        case 'primerSurfacer': {
          const index = this.primerSurfacer.findIndex(v => v.data.name.toLowerCase() === name)
          if (index === -1) {
            this.regularRender()
          } else {
            this.openModalByQuery(index, this.primerSurfacer, 2)
          }
          break
        }
        case 'zincChromate': {
          const index = this.zincChromate.findIndex(v => v.data.name.toLowerCase() === name)
          if (index === -1) {
            this.regularRender()
          } else {
            this.openModalByQuery(index, this.zincChromate, 5)
          }
          break
        }
        case 'ppPrimerSurfacer': {
          const index = this.ppPrimerSurfacer.findIndex(v => v.data.name.toLowerCase() === name)
          if (index === -1) {
            this.regularRender()
          } else {
            this.openModalByQuery(index, this.ppPrimerSurfacer, 4)
          }
          break
        }
        case 'woodCoating': {
          const index = this.woodCoating.findIndex(v => v.data.name.toLowerCase() === name)
          if (index === -1) {
            this.regularRender()
          } else {
            this.openModalByQuery(index, this.woodCoating, 3)
          }
          break
        }
        default: {
          this.regularRender()
        }
      }
    } else {
      this.regularRender()
    }
  },
  watch: {
    productsToShow(newValue) {
      this.disableLeftArrow = newValue[0].data.name === this.productList[0].data.name
      this.disableRightArrow = newValue[newValue.length - 1].data.name === this.productList[this.productList.length - 1].data.name
    },
  },
  methods: {
    shouldHideOverlay(value) {
      this.isLoading = value
    },
    handleClickOutside(event) {
      const dropdownButton = this.$refs.dropDownButton
      const dropdown = this.$refs.dropDown
      if (!this.shouldShowProductLists && dropdownButton.contains(event.target) && !dropdown && !this.disableClickDropdown) {
        this.shouldShowProductLists = true
      } else if (this.shouldShowProductLists && !dropdown.contains(event.target) && dropdown) {
        this.shouldShowProductLists = false
      }
    },
    showNextProducts(ref) {
      if (this.nextArrowDelay || this.prevArrowDelay) return
      this.isLoading = true
      this.disableClickDropdown = true
      ref.setAttribute('data-active', '')
      this.nextArrowDelay = true
      this.prevArrowDelay = true
      this.index = this.productsToShow.length - 1;
      this.intervalId = setInterval(() => {
        if (this.index >= 0) {
          this.$refs.productCard[this.index].removeAttribute('data-active')
          this.index--
        } else {
          setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
            this.$refs.productListScrollContainer.scrollTo({top: 0, behavior: 'smooth'})
            clearInterval(this.intervalId)
            this.showCounter = this.showCounter + this.counterMultiplies
            const productEnd = this.counterMultiplies + this.showCounter
            this.productsToShow = this.productList.slice(this.showCounter, productEnd)
            this.index = 0
            this.page++
            this.disableSeeDetail = true
            this.intervalId = setInterval(() => {
              if (this.index < this.productsToShow.length) {
                this.$refs.productCard[this.index].setAttribute('data-active', '')
                this.index++
              } else {
                clearInterval(this.intervalId)
                this.index = 0
                this.nextArrowDelay = false
                this.prevArrowDelay = false
                this.disableClickDropdown = false
                ref.removeAttribute('data-active')
                this.disableSeeDetail = false
              }
            },100)
          },60)
        }
      },100)
    },
    showPrevProducts(ref) {
      if (this.nextArrowDelay || this.prevArrowDelay) return
      this.isLoading = true
      this.disableClickDropdown = true
      ref.setAttribute('data-active', '')
      this.nextArrowDelay = true
      this.prevArrowDelay = true
      this.index = this.productsToShow.length - 1;
      this.intervalId = setInterval(() => {
        if (this.index >= 0) {
          this.$refs.productCard[this.index].removeAttribute('data-active')
          this.index--
        } else {
          setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
            this.$refs.productListScrollContainer.scrollTo({top: 0, behavior: 'smooth'})
            const productStart = this.showCounter - this.counterMultiplies
            this.productsToShow = this.productList.slice(productStart, this.showCounter)
            this.showCounter = this.showCounter - this.counterMultiplies
            clearInterval(this.intervalId)
            this.index = 0
            this.page--
            this.disableSeeDetail = true
            this.intervalId = setInterval(() => {
              if (this.index < this.productsToShow.length) {
                this.$refs.productCard[this.index].setAttribute('data-active', '')
                this.index++
              } else {
                clearInterval(this.intervalId)
                this.nextArrowDelay = false
                this.prevArrowDelay = false
                this.index = 0
                this.disableClickDropdown = false
                ref.removeAttribute('data-active')
                this.disableSeeDetail = false
              }
            }, 100)
          }, 60)
        }
      }, 100)
    },
    showModal(ref, image, name, description, benefits, detail, usage, color, readyMixUrl, shopeeUrl) {
      if (ref) {
        ref.setAttribute('clicked', '')
        setTimeout(() => {
          this.selectedData = {
            imageUrl: image,
            name: name,
            description: description,
            benefits: benefits,
            detail: detail,
            usage: usage,
            color: color,
            readyMixUrl: readyMixUrl,
            shopeeUrl: shopeeUrl
          }
          this.shouldShowProductDetail = true
          ref.removeAttribute('clicked')
        }, 300)
      } else {
        this.selectedData = {
          imageUrl: image,
          name: name,
          description: description,
          benefits: benefits,
          detail: detail,
          usage: usage,
          color: color,
          readyMixUrl: readyMixUrl,
          shopeeUrl: shopeeUrl
        }
        this.shouldShowProductDetail = true
      }

    },
    closeModal() {
      this.selectedData = {}
      this.shouldShowProductDetail = false
    },
    changeProduct(id, index) {
      this.isLoading = true
      this.disableClickDropdown = true
      switch (id) {
        case 1 :
          this.showSelectedProduct(this.catDumons, 0, index)
          break
        case 2 :
          this.showSelectedProduct(this.clearCoatReducer, 1, index)
          break
        case 3 :
          this.showSelectedProduct(this.primerSurfacer, 2, index)
          break
        case 4 :
          this.showSelectedProduct(this.woodCoating, 3, index)
          break
        case 5 :
          this.showSelectedProduct(this.ppPrimerSurfacer, 4, index)
          break
        case 6 :
          this.showSelectedProduct(this.zincChromate, 5, index)
          break
      }
    },
    direction() {
      document.body.style.overflow = ''
      document.body.removeEventListener('touchmove', this.preventScroll)
      setTimeout(() => {
        this.$router.push('/404')
      }, 2000)
    },
    regularRender() {
      const timeout = sessionStorage.getItem('isFirstMounted') ? 0 : 3000
      this.disableClickDropdown = true
      this.productList = this.catDumons
      this.productsToShow = this.productList.slice(this.showCounter, this.counterMultiplies)
      this.nextArrowDelay = true
      this.currentProduct = this.dropDowns[0].data.name
      this.dropDownsToShow = this.dropDowns
          .slice(0, this.dropDowns.length)
          .filter((_, index) => index !== 0);
      this.$nextTick(() => {
        this.$refs.productListInnerContainer.setAttribute('active', '')
        setTimeout(() => {
          this.intervalId = setInterval(() => {
            if (this.index < this.productsToShow.length) {
              this.$refs.productCard[this.index].setAttribute('data-active', '')
              this.index++
            } else {
              clearInterval(this.intervalId)
              this.index = 0
              this.nextArrowDelay = false
              this.disableSeeDetail = false
              this.disableClickDropdown = false
            }
          },100)
        }, timeout)
      })
    },
    showSelectedProduct(product, number, index) {
      this.nextArrowDelay = true
      this.prevArrowDelay = true
      this.productList = product
      this.index = this.productsToShow.length - 1;
      this.shouldShowProductLists = false
      this.intervalId = setInterval(() => {
        if (this.index >= 0) {
          this.$refs.productCard[this.index].removeAttribute('data-active')
          this.index--
        } else {
          setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
            this.$refs.productListScrollContainer.scrollTo({top: 0, behavior: 'smooth'})
            this.index = 0
            clearInterval(this.intervalId)
            this.showCounter = 0
            this.productsToShow = this.productList.slice(this.showCounter, this.counterMultiplies)
            this.page = 1
            this.currentProduct = this.dropDownsToShow[index].data.name
            this.dropDownsToShow = this.dropDowns
                .slice(0, this.dropDowns.length)
                .filter((_, index) => index !== number);
            this.disableSeeDetail = true
            this.intervalId = setInterval(() => {
              if (this.index < this.productsToShow.length) {
                this.$refs.productCard[this.index].setAttribute('data-active', '')
                this.index++
              } else {
                clearInterval(this.intervalId)
                this.nextArrowDelay = false
                this.prevArrowDelay = false
                this.disableClickDropdown = false
                this.disableSeeDetail = false
              }
            }, 100)
          }, 60)
        }
      }, 100)
    },
    openModalByQuery(index, productCategory, dropDownIndex) {
      const timeout = sessionStorage.getItem('isFirstMounted') ? 0 : 3000
      this.disableClickDropdown = true
      const pageToShow = this.getIndex(index)
      const startIndex = pageToShow * 10
      this.showCounter = startIndex
      const data = productCategory[index]
      this.productList = productCategory
      this.productsToShow = this.productList.slice(startIndex, this.counterMultiplies + startIndex)
      this.nextArrowDelay = true
      this.currentProduct = this.dropDowns[dropDownIndex].data.name
      this.showModal(
          null,
          data.data.imageUrl,
          data.data.name,
          data.data.description,
          data.data.benefits,
          data.data.detail,
          data.data.usage,
          data.data.color
      )
      this.dropDownsToShow = this.dropDowns
          .slice(0, this.dropDowns.length)
          .filter((_, index) => index !== dropDownIndex)
      this.page = pageToShow + 1
      this.$nextTick(() => {
        this.$refs.productListInnerContainer.setAttribute('active', '')
        setTimeout(() => {
          this.intervalId = setInterval(() => {
            if (this.index < this.productsToShow.length) {
              this.$refs.productCard[this.index].setAttribute('data-active', '')
              this.index++
            } else {
              clearInterval(this.intervalId)
              this.index = 0
              this.nextArrowDelay = false
              this.disableSeeDetail = false
              this.disableClickDropdown = false
            }
          },100)
        }, timeout)
      })
    },
    getIndex(value) {
      let result = value / 10

      if (result < 1) {
        return 0;
      } else {
        return Math.floor(result);
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    clearInterval(this.intervalId)
  }
}
</script>

<style>
.product-list-container {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  min-width: 320px;
  max-height: 1000px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  transition: height 1000ms ease;
}
.product-list-inner-container {
  opacity: 0;
  width: 100%;
  height: 100%;
  min-width: 320px;
  max-height: 1000px;
  min-height: 600px;
  max-width: 2000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  transition: opacity 500ms ease, height 1000ms ease;
}
.product-list-inner-container[active] {
  opacity: 1;
}
.product-list-top-section {
  box-sizing: border-box;
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  border-bottom: solid var(--dodger-blue) 10px;

}
.product-list-title {
  font-family: "Bebas Neue", sans-serif;
  color: var(--white);
  text-align: center;
  font-size: 32px;
  margin-top: auto;
  @media (--large) {
    font-size: 42px;
  }
}
.product-list-dropdown-container {
  width: 300px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
}
.product-list {
  position: relative;
  box-sizing: border-box;
  border: solid var(--white) 2px;
  border-radius: 5px;
  width: 70%;
  height: 100%;
  background-color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  transition: background-color 300ms ease, border 300ms ease;
  @media (--large) {
    &:hover {
      background-color: var(--white);
      border: solid black 2px;
      cursor: pointer;
    }
  }
}
.product-list:hover .product-list-placeholder {
  @media (--large) {
    transition: color 300ms ease;
    color: black;
    cursor: pointer;
  }
}
.product-list-placeholder {
  font-size: 16px;
  color: var(--white);
  width: 70%;
}
.product-list-placeholder-image {
  width: 15px;
  height: auto;
  filter: brightness(0) invert(93%);
}
.product-list:hover .product-list-placeholder-image {
  @media (--large) {
    transition: filter 300ms ease;
    filter: brightness(0);
    cursor: pointer;
  }
}
.product-lists {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: auto;
  background-color: black;
  transform: translateY(65%);
  border: solid var(--white) 2px;
  border-radius: 5px;
  z-index: 1000;
}
.product-list-option {
  color: var(--white);
  padding-left: 3px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  height: 25px;
  border-bottom: solid var(--white) 2px;
  @media (--large) {
    &:hover {
      background-color: var(--white);
      color: black;
      cursor: pointer;
    }
  }
}
.product-list-bottom-section {
  box-sizing: border-box;
  width: 100%;
  height: 76%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--light-grey);
  justify-content: center;
}
.product-list-scroll-container {
  box-sizing: border-box;
  padding: 10px;
  column-gap: 20px;
  row-gap: 20px;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  overflow-y: scroll;
  scrollbar-width: none;
  @media (--medium) {
    width: 73%;
  }
}
.product-list-scroll-container::-webkit-scrollbar {
  display: none;
}
.product-card {
  height: 280px;
  width: 230px;
  background-color: var(--white);
  flex-shrink: 0;
  //border-radius: 5px;
  opacity: 0;
  transition: opacity 200ms ease-out;
  margin-bottom: 20px;
  position: relative;
}
.product-card[data-active] {
  transition: opacity 400ms ease-in;
  opacity: 1;
}
.product-card-top-section {
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}
.product-card-grey-background {
  box-sizing: border-box;
  margin-top: 10px;
  width: 100%;
  height: 85px;
  border-right: solid var(--white) 40px;
  border-left: solid var(--white) 40px;
  background-color: lightgrey;
  opacity: 20%;
  z-index: 0;
}
.product-card-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.product-card-image {
  opacity: 1;
  margin-top: 15px;
  height: 120px;
  width: auto;
}
.product-card-bottom-section {
  background: linear-gradient(to bottom, var(--white), #fdf6e3);
  margin-top: 20px;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //border-bottom-left-radius: 5px;
  //border-bottom-right-radius: 5px;
}
.product-card-descriptions {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 90%;
  height: 100%;
}
.product-card-name {
  margin-top: -15px;
  font-size: 24px;
  font-weight: 500;
}
.product-card-description {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
}
.product-card-button {
  margin-bottom: 0;
  margin-top: auto;
  border: none;
  background-color: #fdf6e3;
  height: 30px;
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  //border-bottom-left-radius: 5px;
  //border-bottom-right-radius: 5px;
  transition: background-color 300ms ease, color 300ms ease;
  @media (--large) {
    &:hover:enabled {
      background-color: var(--dodger-blue);
      cursor: pointer;
      color: var(--white);
    }
  }
}
.product-card-button[clicked] {
  background-color: var(--corn-flower-blue);
  color: var(--white);
}
.product-list-button-container {
  margin-top: auto;
  margin-bottom: auto;
  box-sizing: border-box;
  height: auto;
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  //background: linear-gradient(to bottom, var(--dodger-blue), black);
}
.product-list-next-button {
  background-color: transparent;
  width: 20px;
  height: 20px;
  border: none;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-30-512.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover:enabled {
      scale: 1.1;
      cursor: pointer;
      filter: brightness(0) saturate(100%) invert(49%) sepia(87%) saturate(1028%) hue-rotate(353deg) brightness(98%) contrast(93%);
    }
  }
  &:disabled {
    cursor: default;
    filter: brightness(0) saturate(100%) invert(61%) sepia(4%) saturate(125%) hue-rotate(314deg) brightness(92%) contrast(85%);
  }
}
.product-list-next-button[data-active] {
  animation: clickAnimation 0.5s ease;
}
.product-list-prev-button {
  background-color: transparent;
  width: 20px;
  height: 20px;
  border: none;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-95-512.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover:enabled {
      filter: brightness(0) saturate(100%) invert(49%) sepia(87%) saturate(1028%) hue-rotate(353deg) brightness(98%) contrast(93%);
      cursor: pointer;
      scale: 1.1;
    }
  }
  &:disabled {
    cursor: default;
    filter: brightness(0) saturate(100%) invert(61%) sepia(4%) saturate(125%) hue-rotate(314deg) brightness(92%) contrast(85%);
  }
}
.product-list-prev-button[data-active] {
  animation: clickAnimation 0.5s ease;
}
.product-list-badge {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 40px;
  height: 40px;
}
</style>
