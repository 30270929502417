<template>
  <div class="home-header" ref="homeHeader">
    <div class="home-header-top-container" ref="homeHeaderTopContainer">
    <p class="home-header-text" ref="homeButton" @click="direction('/home', this.$refs.homeButton)">Home</p>
    <p class="home-header-text" ref="aboutUsButton" @click="direction('/aboutUs', this.$refs.aboutUsButton)">About Us</p>
    <p class="home-header-text" ref="productListButton" @click="direction('/productList', this.$refs.productListButton)">Product List</p>
    <p class="home-header-text" ref="socialMediaButton" @click="direction('/socialMedia', this.$refs.socialMediaButton)">Social Media</p>
    <p class="home-header-text" ref="contactUsButton" @click="direction('/contactUs', this.$refs.contactUsButton)">Contact Us</p>
    </div>
    <div>
      <img
          src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/icons8-menu-480.png"
          alt="Home Hamburger"
          class="home-header-hamburger"
          @click="handleNavigator"
          ref="hamburger"
      />
      <img
          src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-178-512.png"
          alt="Home Logo"
          class="home-header-logo"
          @click="scrollToTop"
          ref="scrollToTop"
      />
      <div class="home-header-left-navigator-container" @click.self="handleNavigator" ref="leftNavigator">
        <div class="home-header-left-navigator">
          <div class="home-header-navigator-content-bottom-border" ref="homeButtonContainer">
            <p class="home-header-navigator-content" ref="homeButtonL" @click="direction('/home', this.$refs.homeButtonL)">Home</p>
          </div>
          <div class="home-header-navigator-content-bottom-border" ref="aboutUsButtonContainer">
            <p class="home-header-navigator-content" ref="aboutUsButtonL" @click="direction('/aboutUs', this.$refs.aboutUsButtonL)">About Us</p>
          </div>
          <div class="home-header-navigator-content-bottom-border" ref="productListButtonContainer">
            <p class="home-header-navigator-content" ref="productListButtonL" @click="direction('/productList', this.$refs.productListButtonL)">Product List</p>
          </div>
          <div class="home-header-navigator-content-bottom-border" ref="socialMediaButtonContainer">
            <p class="home-header-navigator-content" ref="socialMediaButtonL" @click="direction('/socialMedia', this.$refs.socialMediaButtonL)">Social Media</p>
          </div>
          <div class="home-header-navigator-content-bottom-border" ref="contactUsButtonContainer">
            <p class="home-header-navigator-content" ref="contactUsButtonL" @click="direction('/contactUs', this.$refs.contactUsButtonL)">Contact Us</p>
          </div>
          <div class="home-header-navigator-image-container">
            <img
                src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/logo%20dumons%20putih.png"
                class="home-header-navigator-image"
                alt="nav-image"/>
          </div>
          <div class="home-header-navigator-closer" @click="handleNavigator" ref="homeHeaderNavigatorCloserButton"></div>
        </div>
      </div>
    </div>
    <div class="home-header-whatsapp-container" ref="whatsapp">
      <img ref="wa" @click="redirection('https://wa.me/6285159122501?text=Chat%20with%20our%20admin', this.$refs.wa)" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/whatsapp%20ori.png" alt="wa" class="home-header-whatsapp">
      <div class="home-header-whatsapp-hover">Contact Us!</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeaderPage',
  props: {},
  data() {
    return {
      showNavigator: false,
      disableClick: false,
      scrollCondition: null
    };
  },
  mounted() {
    const location = window.location.href
    if (location.includes('/productList')) {
      if (this.$refs.productListButtonContainer) this.$refs.productListButtonContainer.style.display = 'none'
      if (this.$refs.productListButton) this.$refs.productListButton.style.display = 'none'
    } else if (location.includes('/home')) {
      if (this.$refs.homeButton) {
        this.$refs.homeButton.style.display = 'none'
        this.$refs.aboutUsButton.classList.add('space')
      }
      if (this.$refs.homeButtonContainer) this.$refs.homeButtonContainer.style.display = 'none'
    } else if (location.includes('/aboutUs')) {
      if (this.$refs.aboutUsButton) this.$refs.aboutUsButton.style.display = 'none'
      if (this.$refs.aboutUsButtonContainer) this.$refs.aboutUsButtonContainer.style.display = 'none'
    } else if (location.includes('/socialMedia')) {
      if (this.$refs.socialMediaButton) this.$refs.socialMediaButton.style.display = 'none'
      if (this.$refs.socialMediaButtonContainer) this.$refs.socialMediaButtonContainer.style.display = 'none'
    } else if (location.includes('/contactUs')) {
      if (this.$refs.contactUsButton) this.$refs.contactUsButton.style.display = 'none'
      if (this.$refs.contactUsButtonContainer) this.$refs.contactUsButtonContainer.style.display = 'none'
    }
    window.addEventListener("scroll", this.handleScroll);
    // document.body.style.overflow = ''
    if (window.location.href.includes('productList') || window.location.href.includes('socialMedia') || window.location.href.includes('contactUs') || window.location.href.includes('aboutUs')) {
      this.$refs.hamburger.setAttribute('active', '')
      this.$refs.homeHeaderTopContainer.setAttribute('hide', '')
    } else {
      setTimeout(() => {
        const topHeader = window.document.getElementsByClassName('home-header-text')
        for (let element of topHeader) {
          element.setAttribute('active', '');
        }
        if (this.$refs.whatsapp && !window.location.href.includes('aboutUs') && !window.location.href.includes('socialMedia') && !window.location.href.includes('contactUs')) {
          this.$refs.whatsapp.setAttribute('active', '')
        }
      }, 0)
    }
    this.scrollToTop(true)
  },
  watch: {
    scrollCondition(newVal, oldVal) {
      if (newVal === 'bottom' && oldVal === 'top') {
        setTimeout(() => {
          this.$refs.homeHeaderTopContainer.setAttribute('hide', '')
        }, 210)
      } else if (newVal === 'top' && oldVal === 'bottom') {
        this.$refs.homeHeaderTopContainer.removeAttribute('hide')
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const topHeader = window.document.getElementsByClassName('home-header-text')
      if (window.location.href.includes('productList') || window.location.href.includes('aboutUs') || window.location.href.includes('socialMedia') || window.location.href.includes('contactUs')) return
      if (window.scrollY <= 20) {
        this.scrollCondition = 'top'
        setTimeout(() => {
          for (let element of topHeader) {
            element.setAttribute('active', '');
          }
          this.$refs.hamburger.removeAttribute('active')
          this.$refs.scrollToTop.removeAttribute('active')
        }, 0)
      } else {
        this.scrollCondition = 'bottom'
        setTimeout(() => {
          for (let element of topHeader) {
            element.removeAttribute('active');
          }
          this.$refs.hamburger.setAttribute('active', '')
          this.$refs.scrollToTop.setAttribute('active', '')
        }, 0)
      }
    },
    scrollToTop(isFromMounter = false) {
      if (isFromMounter === true) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } else {
        this.$refs.scrollToTop.setAttribute('clicked', '')
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.$refs.scrollToTop.removeAttribute('clicked')
        }, 500)
      }
    },
    handleNavigator() {
      if (this.disableClick) return
      if (this.showNavigator === false) {
        this.showNavigator = true
        this.$refs.hamburger.removeAttribute('active')
        this.$refs.whatsapp.removeAttribute('active')
        this.$refs.leftNavigator.setAttribute('active', '')
        document.body.style.overflow = 'hidden'
        document.body.addEventListener('touchmove', this.preventScroll, { passive: false })
      } else {
        this.disableClick = true
        this.showNavigator = false
        this.$refs.homeHeaderNavigatorCloserButton.setAttribute('data-active', '')
        setTimeout(() => {
          this.$refs.leftNavigator.removeAttribute('active')
          this.$refs.leftNavigator.setAttribute('deactivate', '')
          setTimeout(() => {
            if (window.location.href.includes('productList') || window.location.href.includes('aboutUs') || window.location.href.includes('contactUs')) {
              this.$refs.hamburger.setAttribute('active', '')
              this.$refs.homeHeaderNavigatorCloserButton.removeAttribute('data-active')
              document.body.style.overflow = ''
              document.body.removeEventListener('touchmove', this.preventScroll);
              setTimeout(() => {
                this.$refs.leftNavigator.removeAttribute('deactivate')
                this.disableClick = false
              }, 500)
            } else {
              this.$refs.whatsapp.setAttribute('active', '')
              this.$refs.homeHeaderNavigatorCloserButton.removeAttribute('data-active')
              document.body.style.overflow = ''
              document.body.removeEventListener('touchmove', this.preventScroll);
              setTimeout(() => {
                this.disableClick = false
                this.$refs.leftNavigator.removeAttribute('deactivate')
              }, 500)
            }
          }, 100)
        }, 200)
      }
    },
    preventScroll(event) {
      event.preventDefault();
    },
    redirection(redirectUrl, ref) {
      ref.setAttribute('clicked', '')
      document.body.style.overflow = ''
      document.body.removeEventListener('touchmove', this.preventScroll)
      setTimeout(() => {
        ref.removeAttribute('clicked')
        window.open(redirectUrl)
      }, 300)
    },
    direction(directionUrl, ref) {
      ref.setAttribute('data-active', '')
      document.body.style.overflow = ''
      document.body.removeEventListener('touchmove', this.preventScroll)
      setTimeout(() => {
        ref.removeAttribute('data-active')
        this.$router.push(directionUrl)
      }, 300)
    },
  }
}
</script>
<style>
.home-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  font-family: "Bebas Neue", sans-serif;
}

.home-header-top-container {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-header-top-container[hide] {
  display: none;
}

.home-header-text {
  margin-top: 23px;
  margin-bottom: auto;
  margin-left: 10px;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  color: white;
  width: auto;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 200ms ease-in-out, color 200ms ease-in-out, scale 200ms ease;
  pointer-events: none;
  @media (--large) {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 35px;
    &:hover {
      color: var(--dodger-blue);
      cursor: pointer;
      scale: 1.1;
    }
  }
}
.home-header-text[active] {
  pointer-events: unset;
  opacity: 1;
}
.home-header-text:first-of-type {
  margin-left: auto;
  @media (--large) {
    margin-left: 28%;
  }
}
.home-header-text:last-of-type {
  margin-right: auto;
  @media (--large) {
    font-size: 20px;
  }
}
.space {
  margin-left: auto;
}
.home-header-text[data-active] {
  animation: clickAnimation 0.3s ease;
}
.home-header-logo {
  position: fixed;
  right: 0px;
  bottom: 15px;
  height: 35px;
  cursor: pointer;
  width: 40px;
  opacity: 0;
  transition: opacity 200ms ease-in-out, scale 300ms ease ;
  pointer-events: none;
  @media (--large) {
    &:hover {
      scale: 1.1;
    }
  }
}
.home-header-logo[active] {
  pointer-events: unset;
  opacity: 1;
}
.home-header-logo[clicked] {
  animation: clickAnimation 300ms ease;
}
.home-header-whatsapp-container {
  position: fixed;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  pointer-events: none;
}
.home-header-whatsapp-container[active] {
  pointer-events: unset;
  opacity: 1;
}
.home-header-whatsapp {
  height: auto;
  width: 50px;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    width: 70px;
    &:hover {
      cursor: pointer;
      scale: 1.1;
    }
  }
}
.home-header-whatsapp[clicked] {
  animation: clickAnimation 300ms ease;
}
.home-header-whatsapp-container:hover .home-header-whatsapp-hover {
  @media (--large) {
    opacity: 1;
    pointer-events: unset;
  }
}
.home-header-whatsapp-hover {
  pointer-events: none;
  position: absolute;
  left: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  width: 100px;
  height: 30px;
  background-color: var(--white);
  border: solid var(--dodger-blue) 2px;
  border-radius: 15px;
  transition: opacity 200ms ease-in-out;
  text-align: center;
  display: flex;
}
.home-header-hamburger {
  left: 20px;
  top: 20px;
  position: fixed;
  height: 25px;
  width: 25px;
  opacity: 0;
  transition: opacity 200ms ease-in-out, scale 300ms ease;
  pointer-events: none;
  @media (--large) {
    height: 30px;
    width: 30px;
    &:hover {
      cursor: pointer;
      scale: 1.1;
    }
  }
}
.home-header-hamburger[active] {
  opacity: 1;
  pointer-events: unset;
}
.home-header-left-navigator-container {
  position: absolute;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  @media (--large) {
    height: 100vh;
  }
}
.home-header-left-navigator-container[active] {
  animation: showFromLeft 500ms ease forwards;
}
.home-header-left-navigator-container[deactivate] {
  animation: hideToLeft 500ms ease;
}
.home-header-left-navigator {
  left: 0;
  top: 0;
  height: inherit;
  width: 250px;
  background-color: var(--dodger-blue);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}
.home-header-navigator-closer {
  top: 3px;
  right: 3px;
  position: absolute;
  display: flex;
  width: 15px;
  height: 15px;
  background-image: url("https://github.com/hanifsyahsn/dumons-asset/blob/main/images/close%20(1).png?raw=true");
  filter: invert(1) brightness(255%) saturate(0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: scale 300ms ease;
  will-change: transform;
  @media (--large) {
    &:hover{
      cursor: pointer;
      scale: 1.1;
    }
  }
}
.home-header-navigator-closer[data-active] {
  animation: clickAnimation 0.2s ease;
}
.home-header-navigator-content-bottom-border {
  box-sizing: border-box;
  height: auto;
  border-bottom: solid var(--white) 3px;
  width: 200px;
}
.home-header-navigator-content {
  margin-top: 25px;
  margin-left: 5px;
  font-size: 25px;
  font-weight: 10;
  color: var(--white);
  transition: color 200ms ease-in-out, scale 200ms ease, margin-left 200ms ease;
  width: fit-content;
  @media (--large) {
    &:hover{
      color: var(--yellow);
      cursor: pointer;
      scale: 1.2;
      margin-left: 15px;
    }
  }
}
.home-header-navigator-content[data-active] {
  animation: clickAnimation 0.3s ease;
}
.home-header-navigator-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding-top: 10px;
  bottom: 0;
  margin-bottom: 50px;
  @media (--small) {
    margin-bottom: 70px;
  }
}
.home-header-navigator-image {
  height: 50px;
  width: 140px;
}
</style>
