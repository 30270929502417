<template>
  <section class="about-us-container" ref="aboutUsContainer">
    <div class="about-us-top-section">
      <h1 class="about-us-title">About Us</h1>
    </div>
    <div class="about-us-bottom-section">
      <div class="about-us-company">
        <img v-for="(card, index) in imageToShow" :key="index" class="about-us-images" :src="card.data.imageUrl" alt="warehouse-img" ref="image" @click="shouldShowModal">
        <div class="about-us-company-description-container" ref="aboutUsCompanyDescriptionContainer">
          <p class="about-us-company-description">
            Perusahaan kami yang berbasis di Boyolali, Jawa Tengah, adalah manufaktur terkemuka di bidang coating cat dan sejenisnya. Kami berkomitmen untuk menghadirkan produk dengan kualitas terbaik di kelasnya, namun tetap menawarkan harga yang terjangkau. Dengan memanfaatkan teknologi mutakhir dari Jerman dan hanya menggunakan bahan-bahan pilihan, kami memastikan bahwa setiap produk yang kami hasilkan memenuhi standar tinggi yang kami tetapkan. Beragam varian produk kami dirancang untuk memenuhi kebutuhan beragam konsumen, mulai dari para profesional hingga pengguna awam, dengan jaminan kemudahan penggunaan dan performa yang andal.
          </p>
        </div>
        <img v-if="isOverflowingDescription" class="about-us-company-up-down-image" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-down-black.png" alt="up-down">
      </div>
      <div class="about-us-vision-mission">
        <div class="about-us-vision-container">
          <h1 class="about-us-vision-title">Our Vision</h1>
          <p class="about-us-vision" ref="vision">
            Menjadi pemimpin industri coating cat yang terpercaya dan inovatif di pasar nasional, dengan terus menghadirkan solusi coating berkualitas tinggi yang ramah pengguna dan mendukung kemajuan teknologi.
          </p>
          <img v-if="isOverflowingVision" class="about-us-company-up-down-image filter-white" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-down-black.png" alt="up-down">
        </div>
        <div class="about-us-mission-container">
          <h1 class="about-us-mission-title">Our Mission</h1>
          <div class="about-us-mission" ref="mission">
            <div class="about-us-data" v-for="(data, index) in mission" :key="index">
              <p style="margin-right: 5px">{{index+1}}.</p>
              <p>{{data.data.tagline}}<br>{{data.data.description}}</p>
            </div>
          </div>
          <img v-if="isOverflowingMission" class="about-us-company-up-down-image filter-white" src="https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-down-black.png" alt="up-down">
        </div>
      </div>
    </div>
  </section>
  <ImageModal v-if="showModal" :image-url="imageToShow[0].data.imageUrl" @closeModal="closeModal"></ImageModal>
</template>

<script>
import ImageModal from "@/components/modal/image_modal/ImageModal.vue";

function missionData(tagline, description) {
  return {
    data: {
      tagline: tagline,
      description: description
    }
  }
}

function imageData(imageUrl) {
  return {
    data: {
      imageUrl: imageUrl,
    }
  }
}
  export default {
    name: 'AboutUsPage',
    components: {ImageModal},
    data () {
      return {
        images: [
          imageData('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg'),
          imageData('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg'),
          imageData('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg'),
          imageData('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg'),
          imageData('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg'),
          imageData('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg'),
          imageData('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg')
        ],
        imageToShow: [],
        isOverflowingDescription: false,
        isOverflowingVision: false,
        isOverflowingMission: false,
        intervalId: null,
        currentSlice: 0,
        showModal: false,
        mission: [
          missionData('Mengutamakan Kualitas:', 'Memproduksi coating dengan standar tinggi menggunakan teknologi Jerman dan bahan pilihan terbaik untuk memastikan kepuasan pelanggan.'),
          missionData('Inovasi Berkelanjutan:', 'Mengembangkan produk yang sesuai dengan kebutuhan dan tren terkini, serta memperkenalkan inovasi yang meningkatkan pengalaman pengguna.'),
          missionData('Pelayanan Pelanggan:', 'Memberikan dukungan penuh kepada konsumen dari berbagai kalangan, memastikan produk mudah digunakan dan efektif.'),
          missionData('Kompetitif dalam Harga:', 'Menawarkan solusi coating dengan harga yang terjangkau tanpa mengorbankan kualitas.'),
          missionData('Tanggung Jawab Sosial:', 'Berkontribusi pada pembangunan komunitas lokal dan menjalankan proses manufaktur yang ramah lingkungan.')
        ]
      }
    },
    mounted() {
      const timeout = sessionStorage.getItem('isFirstMounted') ? 0 : 3000
      this.imageToShow = this.images.slice(this.currentSlice, this.currentSlice + 1)
      this.checkOverflow()
      window.addEventListener('resize', this.checkOverflow)
      setTimeout(() => {
        this.$refs.aboutUsContainer.setAttribute('active', '')
        this.$refs.image[0].setAttribute('active', '')
        setTimeout(() => {
          this.intervalId = setInterval(() => {
            this.$refs.image[0].removeAttribute('active')
            if (this.currentSlice >= this.images.length - 1) {
              this.currentSlice = 0
            } else {
              this.currentSlice = this.currentSlice + 1
            }
            setTimeout(() => {
              this.imageToShow = this.images.slice(this.currentSlice, this.currentSlice + 1)
              setTimeout(() => {
                this.$refs.image[0].setAttribute('active', '')
              }, 10)
            }, 500)
          },10000)
        }, timeout)
      }, 0)
    },
    methods: {
      checkOverflow() {
        const descriptionContainer = this.$refs.aboutUsCompanyDescriptionContainer
        const vision = this.$refs.vision
        const mission = this.$refs.mission
        if (descriptionContainer) {
          this.isOverflowingDescription = descriptionContainer.scrollHeight > descriptionContainer.clientHeight
        }
        if (vision) {
          this.isOverflowingVision = vision.scrollHeight > vision.clientHeight
        }
        if (mission) {
          this.isOverflowingMission = mission.scrollHeight > mission.clientHeight
        }
      },
      shouldShowModal() {
        if (window.innerWidth <= 430 && window.innerHeight >= 600) {
          this.showModal = true
        }
      },
      closeModal() {
        this.showModal = false
      }
    },
    beforeUnmount() {
      clearInterval(this.intervalId)
    }
  }
</script>

<style scoped>

.about-us-container {
  opacity: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  max-height: 1000px;
  min-height: 660px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 500ms ease, height 1000ms ease;
}

.about-us-container[active] {
  opacity: 1;
}

.about-us-top-section {
  width: 100%;
  height: 20%;
  min-height: 100px;
  background-color: black;
  box-sizing: border-box;
  border-bottom: 10px solid var(--dodger-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us-title {
  font-family: "Bebas Neue", sans-serif;
  font-size: 32px;
  background-color: black;
  color: var(--white);
  width: 160px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding-top: 3px;
  @media (--large) {
    border-radius: 40px;
    padding-top: 5px;
    font-size: 42px;
    width: 260px;
  }
}

.about-us-bottom-section {
  box-sizing: border-box;
  width: 100%;
  height: 80%;
  background-color: var(--light-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-company {
  box-sizing: border-box;
  width: 90%;
  height: 60%;
  border-bottom: solid black 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  padding-top: 12px;
  @media (--large) {
    padding-top: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.about-us-images {
  opacity: 0;
  width: auto;
  height: 70%;
  //margin-top: 15px;
  //margin-bottom: -30px;
  transition: opacity 500ms ease-in-out;
  @media (--large) {
    //margin-top: 20px;
    //margin-top: unset;
    width: auto;
    height: 93%;
  }
}
.about-us-images[active] {
  opacity: 1;
}

.about-us-company-description-container {
  width: 100%;
  height: 23.5%;
  //margin-top: 30px;
  overflow-y: scroll;
  @media (--large) {
    //margin-top: unset;
    //margin-bottom: -30px;
    width: 100%;
    height: 93%;
  }
}

.about-us-company-description {
  font-size: 14px;
  text-align: justify;
  font-weight: 350;
  @media (--large) {
    font-size: 18px;
  }
}

.about-us-company-up-down-image {
  position: absolute;
  right: 0;
  bottom: 5px;
  width: 15px;
  height: 15px;
}

.filter-white {
  filter: invert(1) brightness(2);
}

.about-us-vision-mission {
  width: 90%;
  height: 40%;
  box-sizing: border-box;
  border-top: solid black 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 30px;
  //padding-bottom: 5px;
}

.about-us-vision-container {
  width: 52.5%;
  height: 90%;
  background-color: black;
  //border: solid var(--dodger-blue) 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (--large) {
    display: unset;
    flex-direction: unset;
    align-items: unset;
  }
}

.about-us-vision-title {
  text-align: center;
  color: var(--white);
  font-size: 24px;
  margin-top: 15px;
  @media (--large) {
    margin-left: 20px;
    font-size: 32px;
    text-align: left;
  }
}

.about-us-vision {
  text-align: left;
  color: var(--white);
  overflow-y: scroll;
  height: 63%;
  width: 80%;
  margin-top: 10px;
  font-size: 11px;
  word-spacing: 2px;
  @media (--large) {
    font-size: 14px;
    margin-left: 80px;
    margin-right: 80px;
    width: unset;
  }
}

.about-us-mission-container {
  width: 52.5%;
  height: 90%;
  background-color: black;
  //border: solid var(--dodger-blue) 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (--large) {
    display: unset;
    flex-direction: unset;
    align-items: unset;
  }
}

.about-us-mission-title {
  color: var(--white);
  text-align: center;
  margin-top: 15px;
  @media (--large) {
    margin-left: 20px;
    font-size: 32px;
    text-align: left;
  }
}

.about-us-mission {
  margin-top: 10px;
  color: var(--white);
  text-align: left;
  font-size: 11px;
  overflow-y: scroll;
  height: 63%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  word-spacing: 2px;
  @media (--large) {
    font-size: 14px;
    margin-left: 80px;
    margin-right: 80px;
    width: unset;
  }
}
.about-us-data {
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
}


</style>
