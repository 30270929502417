export const images = [
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/1.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/2.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/3.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-30-512.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-95-512.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/705.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/901.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/All%20Produk%20png_20241023_131329_0002.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/ArrowLeftDisabled.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/ArrowLeftHover.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/ArrowRightDisabled.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/ArrowRightHover.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20CANDY%20PU.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20FLUORESCENT%20PU.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20NC%20METALLIC.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20NC%20MOTOR.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20NC%20SOLID.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20TINTING%20PU.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20ULTIMATE%20PU%20MOBIL.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/EPOXY%20FILLER.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/EPOXY%20PRIMER%20GREEN.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/EPOXY%20PRIMER%20SURFACER.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/GEO.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_1028.jpeg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155231_616.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_083.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_633.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_657.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_714.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/IMG_20241015_155306_932.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/MELAMINE%20CLEAR%20DOFF.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/MELAMINE%20CLEAR%20GLOSS.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/MELAMINE%20SANDING%20SEALER.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/PP%20BLACK%20MATTE.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/PP%20LIGHT%20GREY.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/PP%20SILVER%20HALUS.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/PREMIUM.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/S1.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/S2.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/SAVE_20241005_141210.jpg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/SURFACER%20PU%202K.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/THINNER%20109.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/THINNER%20XTRA%20SLOW.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WOOD%20FILLER.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WOOD%20STAIN.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-13%20at%2022.07.55.jpeg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.21.jpeg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(1).jpeg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22%20(2).jpeg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.22.jpeg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WhatsApp%20Image%202024-10-20%20at%2021.52.23.jpeg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/ZINC%20CHROMATE.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-178-512.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-down-black.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-down.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/best-seller%20(2).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/black-background.jpg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/bluetruck.jpg',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/bukan-kaleng-kaleng-dark.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/close%20(1).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/cross.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/customer-service%20(1).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/gps%20(1).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/icons8-menu-480.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/instagram%20(1).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/instagram.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/logo%20dumons%202020.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/logo%20dumons%20putih.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/mail%20(1).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/medsos.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/pngwing.com%20(4).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/pngwing.com%20(5).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/stiker%20mobil%20aris.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/tik-tok%20(1).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/tik-tok.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/up-and-down-arrow%20(2).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/up-down%20(1).png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/whatsapp%20ori.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/whatsapp.png',
    'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/x-button.png'
]
